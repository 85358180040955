import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timi-evaluation-container" }
const _hoisted_2 = { class: "top-action-bar" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = { class: "col-9" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = {
  key: 0,
  class: "row"
}
const _hoisted_9 = {
  key: 0,
  class: "top-action-bar"
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "top-action-bar row" }
const _hoisted_12 = { class: "action-bar-label" }
const _hoisted_13 = {
  key: 1,
  class: "row"
}
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = {
  key: 2,
  class: "row"
}
const _hoisted_16 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_OptionButtonGroup = _resolveComponent("OptionButtonGroup")!
  const _component_SelectSingleSearch = _resolveComponent("SelectSingleSearch")!
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Card = _resolveComponent("Card")!
  const _component_UiTab = _resolveComponent("UiTab")!
  const _component_UiTabs = _resolveComponent("UiTabs")!
  const _component_MultiplePresenceForm = _resolveComponent("MultiplePresenceForm")!
  const _component_MultipleWorkbreakForm = _resolveComponent("MultipleWorkbreakForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DateInput, {
            value: _ctx.formDateInputValue(_ctx.evaluationFrom),
            onOnChange: _cache[0] || (_cache[0] = (value) => _ctx.onEvaluationDateChange(value, 'from')),
            class: "inline"
          }, null, 8, ["value"]),
          _createTextVNode(" - "),
          _createVNode(_component_DateInput, {
            value: _ctx.formDateInputValue(_ctx.evaluationTo),
            onOnChange: _cache[1] || (_cache[1] = (value) => _ctx.onEvaluationDateChange(value, 'to')),
            class: "inline"
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_OptionButtonGroup, {
            primary: "true",
            value: _ctx.displayTimeRange,
            options: _ctx.displayTimeRangeOptions,
            size: "small",
            onOnChange: _ctx.onDisplayTimeRangeChange
          }, null, 8, ["value", "options", "onOnChange"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.employees)
            ? (_openBlock(), _createBlock(_component_SelectSingleSearch, {
                key: 0,
                placeholder: _ctx.$t('placeholder.employee'),
                item: { label: _ctx.$t('label.employee'), name: 'selectEmployee' },
                options: _ctx.selectableEmployees,
                labelProperty: "fullname",
                value: { value: _ctx.selectedEmployee.uid, text: _ctx.selectedEmployee.fullname },
                allowCreateNew: false,
                emptyStateText: _ctx.$t('emptyState.employees'),
                onOnChange: _ctx.onEmployeeChange
              }, null, 8, ["placeholder", "item", "options", "value", "emptyStateText", "onOnChange"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (!(_ctx.editPresences.length || _ctx.editWorkbreaks.length))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_UiTabs, {
            tabs: [_ctx.$t('label.evaluation.tabs.presences'), _ctx.$t('label.evaluation.tabs.works')],
            hideTabsHead: false,
            class: "col-12",
            onOnSetActiveTab: _cache[4] || (_cache[4] = (idx) => _ctx.activeTab = idx)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UiTab, {
                class: _normalizeClass({'active': _ctx.activeTab == 0})
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: _ctx.$t('label.evaluation.times')
                  }, {
                    default: _withCtx(() => [
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_Searchbar, {
                              onOnValueChange: _cache[2] || (_cache[2] = (input) => _ctx.searchInput = input)
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.employeeStatistics.length)
                        ? (_openBlock(), _createBlock(_component_Table, {
                            key: 1,
                            class: "header-small",
                            tableData: _ctx.employeeTableData,
                            src: _ctx.employeeStatistics,
                            emptyStateText: _ctx.$t('emptyState.employees'),
                            onOnActionClicked: _ctx.onTableActionClicked
                          }, null, 8, ["tableData", "src", "emptyStateText", "onOnActionClicked"]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('emptyState.employees')), 1))
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_UiTab, {
                class: _normalizeClass({'active': _ctx.activeTab == 1})
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Card, {
                    title: _ctx.$t('label.evaluation.works')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_Searchbar, {
                          onOnValueChange: _cache[3] || (_cache[3] = (input) => _ctx.searchInput = input)
                        }),
                        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('label.workSum')) + ": " + _toDisplayString(_ctx.convertSecondsToHours(_ctx.workTimeSum)) + " h", 1)
                      ]),
                      (_ctx.employee.timeEntries?.length)
                        ? (_openBlock(), _createBlock(_component_Table, {
                            key: 0,
                            class: "header-small",
                            tableData: _ctx.employeeWorkTabelData,
                            src: _ctx.filteredEmployeeTimeEntries,
                            emptyStateText: _ctx.$t('emptyState.employees'),
                            onOnActionClicked: _ctx.onTableActionClicked
                          }, null, 8, ["tableData", "src", "emptyStateText", "onOnActionClicked"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          }, 8, ["tabs"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.editPresences.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Card, {
              title: _ctx.$t('headline.editPresence'),
              class: "transparent border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MultiplePresenceForm, {
                  presences: _ctx.editPresences,
                  onUpdate: _ctx.onPresenceEditFormUpdate,
                  onDelete: _ctx.onPresenceEditFormDelete,
                  onClose: _ctx.onPresenceEditFormClose,
                  onError: _ctx.onPresenceEditFormError
                }, null, 8, ["presences", "onUpdate", "onDelete", "onClose", "onError"])
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.editWorkbreaks.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_Card, {
              title: _ctx.$t('headline.editWorkbreaks'),
              class: "transparent border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MultipleWorkbreakForm, {
                  workbreaks: _ctx.editWorkbreaks,
                  onUpdate: _ctx.onWorkbreakEditFormUpdate,
                  onDelete: _ctx.onWorkbreakEditFormDelete,
                  onClose: _ctx.onWorkbreakEditFormClose
                }, null, 8, ["workbreaks", "onUpdate", "onDelete", "onClose"])
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}